import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/font.css'
import './assets/style/common.css'
import store from './store'
import scroll from 'vue-seamless-scroll'
import VUEx from 'vuex'
// 引入api
import api from './api/axios'
// 引入cookies
import VueCookies from 'vue-cookies'
// 将API方法绑定到全局
Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(scroll)
Vue.use(api)
Vue.use(VUEx)
new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
