import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    // 账户密码登录
    path: '/login/login',
    name: 'login',
    component: () => import('@/views/login/login')
  },
  {
    // 手机号快捷登录
    path: '/login/quick_login',
    name: 'quick_login',
    component: () => import('@/views/login/quick_login')
  },
  {
    // 忘记密码
    path: '/login/update_pwd',
    name: 'update_pwd',
    component: () => import('@/views/login/update_pwd')
  },
  {
    // 忘记密码_一下步
    path: '/login/new_pwd',
    name: 'new_pwd',
    component: () => import('@/views/login/new_pwd')
  },
  {
    // 企业注册
    path: '/login/registration',
    name: 'registration',
    component: () => import('@/views/login/registration')
  },
  {
    // 企业注册——下一步
    path: '/login/registration_step',
    name: 'registration_step',
    component: () => import('@/views/login/registration_step')
  },
  {
    // 个人注册——下一步
    path: '/login/personal_reg_step',
    name: 'personal_reg_step',
    component: () => import('@/views/login/personal_reg_step')
  },
  {
    // 招募公告
    path: '/Winning',
    name: 'Winning',
    component: () => import('@/views/Winning')
  },
  {
    // 招募公告
    path: '/Recruiting',
    name: 'Recruiting',
    component: () => import('@/views/Recruiting')
  },
  {
    // 企业服务
    path: '/Service',
    name: 'Service',
    component: () => import('@/views/Service')
  },
  {
    // 帮助中心
    path: '/Help',
    name: 'Help',
    component: () => import('@/views/Help')
  },
  {
    // 申诉
    path: '/Appeal',
    name: 'Appeal',
    component: () => import('@/components/service/appeal')
  }
]

const router = new VueRouter({
  routes,
  // 以下用于路由跳转 页面保持在顶部
  mode: 'hash',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  var id = localStorage.getItem('EUJSESSIONID')
  if (to.name === 'Service') {
    if (id === '' || id === null) {
      router.replace('/login/login')
    }
  }
  next()
})
export default router
