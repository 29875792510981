import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: '',
    EUJSESSIONID: '',
    regID: '',
    msgID: ''
  },
  mutations: {
    setId (state, token) {
      state.token = token
    },
    setmsgID (state, msgID) {
      state.msgID = msgID
    },
    setEUJSESSIONID (state, EUJSESSIONID) {
      state.EUJSESSIONID = EUJSESSIONID
    },
    setRegId (state, regID) {
      state.regID = regID
    }
  },
  getters: {
    getId: state => {
      return state.token
    },
    getmsgID: state => {
      return state.msgID
    },
    getEUJSESSIONID: state => {
      return state.EUJSESSIONID
    },
    getRegId: state => {
      return state.regID
    }
  }
})

export default store
